<template>
  <div id="ReelPage">
    <video controls playsinline autoplay class="reel">
        <source src="../assets/Reb J 2024 Motion Reel.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
// import { toRaw } from 'vue';

export default {
  name: 'TextWithImage',
  components: {
  },
  watch: {
  
  },
  props: {
    data: {
        type: Object,
        default: () => {}
    }
  },
  data() {
    return {
    }
  },
  emits: [""],
  methods: {

  },
  beforeMount() {
  },
  mounted() {

  }
}
</script>

<style lang="scss">
@import "~@/styles.scss";

#ReelPage {
//   max-width: $maxwidth;
  margin: auto;
//   padding: 0 $mobilemargin;
}

.reel {
    max-width: 1000px;
    width: 100%;
}
</style>
