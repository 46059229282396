<template>
  <div id="contact">
    <div class="contactform" ref="contactform">
        <div class="message-submitted">
        <h2>
        <span class="row">
            <span class="row-inner">
              YOUR <span class="courier"> MESSAGE</span> HAS
            </span>
        </span>
          <span class="row">
            <span class="row-inner">
            <span class="row">BEEN RECEIVED.</span>
          </span>
          </span>
        </h2>
      </div>
        <form @submit="handleSubmit" ref="form">
          <table>
            <tr>
              <td class="labeller">
                <label>Name</label>
              </td>
              <td class="inputter">
                <input type="text" ref="name" required/>
              </td>
            </tr>
            <tr>
              <td class="labeller">
                <label>Email</label>
              </td>
              <td class="inputter">
                <input  ref="email" required/>
                <!-- type="email" -->
              </td>
            </tr>
            <tr>
              <td class="labeller">
                <label>Message</label>
              </td>
              <td class="inputter">
                <textarea rows="10" ref="message" required></textarea>
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="inputter">
               <a class="submit-a">
                <input class="submit-ghost" type="submit">
                <strong class="submit">
                 SUBMIT
                </strong>
               </a>
              </td>
            </tr>

          </table>
        </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import Email from "../smtp.js";
export default {
  name: 'ContactSection',
  components: {
  },
  watch: {

  },
  data() {
    return {
      showSuccess: false,
      timeout: null,
      message: 'nessage'
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let name = this.$refs.name.value;
      let email = this.$refs.email.value;
      this.message = this.$refs.message.value;

      Email.send({
        SecureToken : "f576814f-285b-4ce7-9ae9-1d2f35fc62ca",
        To : 'rebjanec@gmail.com',
        From : "carllective@rebj.ca",
        Subject : `From rebj.ca`,
        Body : `
        Name: ${name} <br/>
        Email: ${email} <br/>
        Message: ${this.message}
        `
    }).then(status => {
      console.log(status)
      if (status === "OK") {
        this.showSuccess = true;
        this.$refs.contactform.className += ` submitted`;
        this.$refs.form.reset();
        this.timeout = setTimeout(() => {
          this.showSuccess = false;
          clearTimeout(this.timeout);
        }, 5000);
      }
    });
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles.scss";

#contact {
  position: relative;
  mix-blend-mode: difference;
}

.contactform {
  max-width: $maxwidth;
  text-align: left;
  margin: auto;
  margin: 0 auto;
  padding: 0 $desktopmargin;
  @media screen and (max-width: $mobiledown) {
    padding: 0 20px;
  }


  label {
    font-family: "Helvetica Neue";
    text-transform: uppercase;
    padding-right: 20px;
    font-size: $size-header;
    display: block;
    @media screen and (max-width: $mobiledown) {
      font-size: $size-subheader;
    }
  }
  table {
    width: 100%;
    border-spacing: 0 10px;
    td {
      @media screen and (max-width: $mobiledown) {
        display: block;
      }
    }
  }
  input, textarea {
    outline: none;
    width: calc(100% - 22px);
    font-family: Courier;
    padding: 20px 10px;
    background: none;
    border: 1px solid white;
    color: white;
    font-size: $size-body;
    transition: atransformll 1s $transition;
    transform: translateY(0%);
  }
  .labeller {
    display: inline-block;
    overflow: hidden;
    label {
      color: white;
    }
  }
  .inputter {
    width: 100%;
    overflow: hidden;
  }
  .submit {
    font-family: "Helvetica Neue";
    text-transform: uppercase;
    font-size: 80px;
    padding: 0;
    text-align: left;
    border: none;
    width: 100%;
    cursor: pointer;
    color: white;
  }
  .submit-ghost {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
    cursor: pointer;
  }
  .submit-a {
    position: relative;
    transition: transform 1s $transition;
    transform: translateY(0%);
    font-size: 80px;
  }
  strong {
    font-size: 80px;
    &:after {
      color: white;
    }
  }
}
.submitted {
  .inputter, .labeller {
    input, textarea, label, .submit-a {
      transform: translateY(calc(-100% - 40px));
      transition: transform 1s $transition;
    }
  }
  .message-submitted  {
    color: white;

    .row {
      .row-inner {
        transition: transform 1s $transition .25s;
        transform: translateY(0%);
      }
    }

    .message-submitted {
      pointer-events: auto;
    }
  }
  
}

.message-submitted {
  pointer-events: none;
  max-width: 600px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 4;

  h2 {
    font-size: $size-header;
    line-height: 1.15;
    margin: 0;
    text-align: center;

    span {
      font-size: $size-header;
      display: inline-block;
      line-height: 1.15;
      position: relative;
    }
    .courier {
      font-family: Courier;
      font-weight: 100;
      font-size: calc($size-header + 10px);
    }
  }

  .row {
    overflow: hidden;
    .row-inner {
      transition: transform 1s $transition .25s;
      transform: translateY(100%);
      display: block;
    }
  }
}
</style>
