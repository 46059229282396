import { createApp } from 'vue';
import Index from './Index.vue';
import router from './router';
import prismic from './prismic';
import store from './store';
var PrismicDOM = require("prismic-dom");

const app = createApp(Index);

app.config.globalProperties.$prismic = prismic;

app.config.globalProperties.$cms = new (class CMSModule {
  text(field) {
    return PrismicDOM.RichText.asText(this.fields[field]);
  }

  textField(field) {
    return PrismicDOM.RichText.asText(field);
  }

  html(field) {
    return PrismicDOM.RichText.asHtml(this.fields[field]);
  }

  htmlField(field) {
    return PrismicDOM.RichText.asHtml(field);
  }
})();


app
.use(router)
.use(store)
.mount('#app')
