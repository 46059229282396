import store from "./store.js";

export default class Scrolly {
  constructor(elParent, orientation) {
    this.elParent = elParent;
    this.el = this.elParent.children[0];
    this.pos = 0;
    this.dir = 0;
    this.orientation = orientation ? orientation : "vertical";
    this.scrollTo = 0;
    this.deaf = false;
    this.max;
    this.resizeScreen();
    this.eventListeners = this.eventListeners.bind(this);
    this.scroll = this.scroll.bind(this);
    this.prevTouch = 0;
    this.device = null;
    this.doScroll = false;

    if (window.navigator.userAgent.includes("iPhone")) {
      this.device = "mobile";
    } else if (window.navigator.userAgent.includes("Android")) {
      this.device = "mobile";
    } else if (window.navigator.userAgent.includes("iPad")) {
      this.device = "mobile";
    } else if (window.navigator.userAgent.includes("Firefox")) {
      this.device = "firefox";
    } else {
      this.device = "desktop";
    }

    this.eventListeners();
    
  }
  deafen() {
    this.deaf = true;
  }
  fullDeafen() {
    this.deaf = true;
    this.doScroll = false;
  }
  resizeScreen() {
    
    switch(this.orientation) {
      case "vertical" : {
        this.max = this.el.offsetHeight - window.innerHeight;
        break;
      }
      case "horiz": {
        this.max = this.el.offsetWidth - this.elParent.offsetWidth;
        break;
      }
    }
  }
  listen() {
    this.deaf = false;
  }
  scroll_To(to) {
    this.scrollTo = Math.ceil(to);
    this.scrollTo = this.limit(this.scrollTo, this.max);
    this.initiateScroll();
  }
  eventListeners() {
    if (this.device === "desktop") {
      window.addEventListener("mousewheel", (e) => {
        // Ensure page isn't natively-scrolled away from tabbing
        document.getElementById("index").scrollTop = 0;

        if (this.deaf) return;
        if (!this.doScroll) this.initiateScroll();
        
        this.dir = Math.abs(e.deltaY) / e.deltaY;
        this.scrollTo += e.deltaY;

        this.scrollTo = this.limit(this.scrollTo, this.max);
        e.stopPropagation();
      });
    } else if (this.device === "mobile") {
      window.addEventListener("touchstart", (e) => {
        e.preventDefault();
        // Ensure page isn't natively-scrolled away from tabbing
        document.getElementById("index").scrollTop = 0;

        if (this.deaf) return;

        this.prevTouch = e.touches[0].screenY;
      });
      window.addEventListener("touchmove", (e) => {
        e.preventDefault();
        if (this.deaf) return;
        if (!this.doScroll) this.initiateScroll();

        if (this.prevTouch !== e.touches[0].screenY) {
          this.nowTouch = e.touches[0].screenY;
          this.scrollTo += (this.prevTouch - this.nowTouch) * 3;
          this.scrollTo = this.limit(this.scrollTo, this.max);
          e.stopPropagation();
        }
        this.prevTouch = e.touches[0].screenY;
      });
    } else {
      window.addEventListener("wheel", (e) => {
        if (this.deaf) return;
        this.dir = Math.abs(e.deltaY) / e.deltaY;
        this.scrollTo += e.deltaY;

        this.scrollTo = this.limit(this.scrollTo, this.max);
        e.stopPropagation();
      });
    }
  }

  initiateScroll() {
    this.doScroll = true;
    this.scroll();
    // this.doScroll = false;
  }

  scroll() {
    // requestAnimationFrame(this.scroll);
    if (this.doScroll) requestAnimationFrame(this.scroll);
    else cancelAnimationFrame(this.scroll);

    this.pos += (this.scrollTo - this.pos) / 75; // higher then number, slower the scroll
    store.commit("setScrollPos", this.pos);
    
    if (this.scrollTo === Math.floor(this.pos) || this.scrollTo === Math.ceil(this.pos)) {
      this.doScroll = false;
      return;
    }

    
    switch(this.orientation) {
      case "vertical" : {
        this.el.style.transform = `translate3d(0,${-1 * this.pos}px,0)`;
        break;
      }
      case "horiz": {
        this.el.style.transform = `translate3d(${-1 * this.pos}px,0,0)`;
        break;
      }
    }
  }
  limit(min, max) {
    return Math.max(Math.min(min, max), 0);
  }
}
