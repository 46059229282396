<template>
  <div class="nav" id="nav">
    <a href="/" v-show="!activeComponent">
      <img class="logo" src="~@/assets/designbyrebj-badge.png"/>
    </a>

    <div class="go-back" v-show="activeComponent && navOpen">
      <ArrowCTA class="arrow" color="white"/>
    </div>

    <div class="resume" v-if="resumeDownload">
      <a :href="resumeDownload" target="_blank">
        <small>
        Download Resume
      </small>
      </a>
    </div>

    <footer>
      <div :class="`socials ${navOpen ? `white center` : ``}`">
        <small v-for="(item, i) in socials" :key="i"><a :href="item.link" target="_blank">{{ item.text }}</a> {{i !== socials.length - 1 ? `– ` : ``}} </small>
      </div>
      <div class="copyright">
        <small>Copyright © {{ new Date().getFullYear() }} Design by Reb J. All Rights Reserved.</small>
      </div>
    </footer>

    <div :class="`hamburger ${navOpen ? `white` : ``}`" ref="hamburger">
      <div :class="`top line`">––––</div>
      <div :class="`mid line`">––––</div>
      <div :class="`bottom line`">––––</div>
    </div>

    <transition appear name="nav">
      <div class="nav_drawer" v-if="navOpen">
        <div class="up half"></div>
        <div class="down half"></div>
       
        <!-- <transition appear name="fadeinup">
          <img class="logoinner" src="~@/assets/designbyrebj-badge.png" :key="navOpen"/>
        </transition> -->
        <transition appear name="menu-slide-in">
          <ul class="menu" :key="navOpen + activeComponent" v-if="navOpen && !activeComponent" >
            <transition v-for="(item, i) in navMenu" :key="i+navOpen+activeComponent" appear name="navlist" >
              <li :style="`transition: all .5s ease ${((i+1) * 100) + 100}ms`">
                <span 
                :href="item.fields.Link" 
                :scrollToID="item.fields.scrollToID || null"
                :component="item.fields.component"
                :extlink="item.fields.ExtLink"
                class="navlink-outer"
                :id="item.fields.Link">
                    <a class="navlink" :href="item.fields.Link">{{ item.fields.Text }}</a>
                    <span class="ghost-navLink">{{ item.fields.Text }}</span>
                </span>
              </li>
            </transition>
          </ul>
        </transition>

         <transition appear name="component-slide-in">
          <component class="component" 
          v-if="activeComponent"
          :is="activeComponent" 
          :key="activeComponent"
          :clickObj="clickObj"
          @closeNav="navOpen = false"></component>
         </transition>

      </div>
    </transition>

  </div>
</template>

<script>
import { gsap } from "gsap";
import ReelPage from "./ReelPage";
import WorkList from "./WorkList";
import ContactSection from "./ContactSection";
import ArrowCTA from "./ArrowCTA";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { toRaw } from 'vue';

gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
export default {
  name: 'NavMenu',
  components: {
    ReelPage,
    ArrowCTA,
    ContactSection,
    WorkList
  },
  props: {
    clickObj: {
      type: Object,
      default: () => {}
    },
    homepageData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    navOpen: {
      handler(navOpen) {
        this.animate();
        if (!navOpen) {
          this.activeComponent = null;
        }
      }
    },
    homepageData: {
      handler(homepageData) {
        if (homepageData) {
          this.navMenu.filter(i => {
            // if (i.fields.Text.includes("résumé")) i.fields.ExtLink = toRaw(homepageData).data.resume.url;
            if (i.fields.Text.includes("services")) i.fields.ExtLink = toRaw(homepageData).data.services.url;
          })
        }
      }
    },
    clickObj: {
      handler(clickObj) {

        if (!clickObj.target.className || !clickObj.target || !clickObj) return;

        if (clickObj.target.className.includes("hamburger")) {
          this.navOpen = !this.navOpen;
          return;
        }

        // Go Back
        if (clickObj.target.className.includes("go-back")) {
          this.activeComponent = null;
          return;
        }

        // Menu link
        if (clickObj.target.className.includes("navlink-outer")) {
          // If this is a scrolling link
          if (clickObj.target.getAttribute('scrollToID')) {
            this.$store.state.scrolly.scroll_To(document.getElementById(clickObj.target.getAttribute('scrollToID')).offsetTop)
            return;
          }

          // If this is an embedded component link
          if (clickObj.target.getAttribute('component')) {
            this.activeComponent = clickObj.target.getAttribute('component');
            return;
          }

          // If this is an external link
          if (clickObj.target.getAttribute('extlink')) {
            window.open(clickObj.target.getAttribute('extlink'), '_blank');
            return;
          }

          this.$router.push(clickObj.target.getAttribute('href'))
        } 
      }
    },
  },
  data() {
    return {
      socials: [
        {
          text: "Instagram",
          link: "https://www.instagram.com/designbyrebj/"
        },
        {
          text: "Linkedin",
          link: "https://www.linkedin.com/in/rebj/"
        },
        {
          text: "Facebook",
          link: "https://www.facebook.com/officialdesignbyrebj"
        },
      ],
      hamburgerLength: null,
      navOpen: false,
      timeline: gsap.timeline(),
      clickEventListener: null,
      resumeDownload: null,
      navMenu: [
        {
          fields: {
            Text: "reel",
            Link: "./reel",
            ExtLink: null,
            scrollToID: null,
            component: "ReelPage"
          }
        },
        {
          fields: {
            Text: "work",
            Link: "",
            ExtLink: null,
            scrollToID: null,
            component: "WorkList"
          }
        },
        {
          fields: {
            Text: "contact",
            Link: "",
            ExtLink: null,
            scrollToID: null,
            component: "ContactSection"
          }
        },
        // {
        //   fields: {
        //     Text: "résumé",
        //     Link: "",
        //     ExtLink: null,
        //     scrollToID: null,
        //     component: null
        //   }
        // },
        {
          fields: {
            Text: "services",
            Link: "",
            ExtLink: null,
            scrollToID: null,
            component: null
          }
        }
      ],
      activeComponent: null
    }
  },
  emits: ["navOpen"],
  methods: {
    closeNavWhenClickingLinks(e) {
      if (e.target.className.includes("navlink")) {
        // this.animate();
        this.$nextTick(() => {
          e.preventDefault();
          this.$router.push("");
          this.$router.push(e.target.id);
          // need to scroll to section
        })
      }
    },
    animate() {
      if (this.navOpen) {
        toRaw(this.timeline).restart();
        toRaw(this.timeline).to(".top", {y: 10, duration: 1, rotation: 45, }, 0);
        toRaw(this.timeline).to(".bottom", {x: -9, y: -10, duration: 1, rotation: -45}, 0);
        toRaw(this.timeline).to(".mid", { duration: .25, opacity: 0}, 0);
      } else {
        toRaw(this.timeline).reverse();
      }
      this.$emit("navOpen", this.navOpen);
    }
  },
  beforeMount() {
  },
  mounted() {
    this.hamburgerLength = this.$refs.hamburger.getBoundingClientRect().width;
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles.scss";

.navlink {
  color: black;
}

.navlink-outer {
  cursor: pointer;

  .ghost-navLink {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 3rem;
    font-family: Courier;
    color: black !important;
    transform:translateX(-50%) translateY(200%) scale(1,3); /* W3C */
    font-weight: 100;
    transition: transform 1s ease;
    pointer-events: none;
    opacity: 0;
    @media screen and (max-width: $mobiledown) {
      font-size: $size-subheader;
    }
  }
  &:hover {
    .ghost-navLink {
      opacity: 1;
      transition: transform .5s ease;
      transform:translateX(-50%) translateY(-50%) scale(1,3); /* W3C */
    }
    .navlink {
      transition: transform .5s ease;
      transform:translateY(-100%); /* W3C */
    }
  }
}

.logo {
  position: fixed;
  left: 20px;
  width: 50px;
  height: 50px;
  top: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
  @media screen and (max-width: $mobiledown) {
    top: 20px;
    transform: translateY(0%);
  }
}
.logoinner {
  width: 80px;
  height: 80px;
  position: fixed;
  z-index: 11;
  transform: translateX(-50%);
  left: 50%;
  top: 20px;

}
.socials {
  position: fixed;
  z-index: 11;
  bottom: 20px;
  left: 20px;
  transition: all 1.5s $transition;
  color: white;
  mix-blend-mode: difference;
  @media screen and (max-width: $mobiledown) {
      width: 100%;
    }
  
  &:not(.center, .white) {
    @media screen and (max-width: $mobiledown) {
        display: none;
    }
  }
  a {
    position: relative;
    &:before {
      width: 0%;
      height: 2px;
      background: black;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      transition: width .5s $transition;
    }
    &:hover {
      &:before {
        width: 100%;
        transition: width .5s $transition;
      }
    }
  }

}
.copyright {
  position: fixed;
  bottom: 20px;
  right: 20px;
  mix-blend-mode: difference;
  color: white;

  @media screen and (max-width: $mobiledown) {
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    padding: 0 20px;
  }
}
.hamburger {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  // top: 20px;
  cursor: pointer;
  z-index: 11;
  @media screen and (max-width: $mobiledown) {
    top: 20px;
    transform: translateY(0%);
  }
}
.line {
  height: 10px;
  transform-origin: center;
  pointer-events: none;
}
.hamburger {
  // color: black;
  color: white;
  transition: all 1s $transition;
  mix-blend-mode: difference;
}
a, span {
  // color: black;
  color: white;
  text-decoration: none;
  transition: all 1s $transition;
}
.white {
  // color: black;
  // color: white;
  transition: all 1s $transition;
  a, span {
    // color: black;
    // color: white;
    transition: all 1s $transition;
  }
}

.nav_drawer {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
  height: 100vh;
  z-index: 4;
  overflow: hidden;
  max-height: 100vh;
  transition: max-height 1.5s $transition;

  .half {
    transition: all 1s $transition;
    height: 100%;
    max-height: 50.1vh;
    width: 100vw;
    background-image: url(".~@/assets/paper-texture-dark.jpg");
    position: fixed;
    transition: all 1s $transition;
  }
  .up {
    bottom: 49.9%;
  }
  .down {
    top: 49.9%;
  }
  .menu {
    list-style: none;
    position: fixed;
    z-index: 11;
    // color: white;
    font-family: Res;
    font-weight: bold;
    // left: 50%;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%) translateY(0%);
    padding: 0;
    margin: 0;
    max-height: 70vh;
    overflow: scroll;
    // padding-top: 3rem;
    transition: transform 1.5s $transition;
    
    span {
      // color: white;
      color: darkgrey;
      transition: all 1s $transition;

      &:hover {
        transition: all 1s $transition;
        color: black;
      }
    }
    a {
      font-size: 4rem;
      margin: 0;
      display: block;
      font-family: "Helvetica Neue";
      pointer-events: none;
      text-transform: uppercase;

      @media screen and (max-width: $mobiledown) {
        font-size: 40px;
      }
    }
    li {
      opacity: 1;
      transform: translateY(0%);
      display: block;
      // padding: 2rem 0;
      overflow: hidden;
    }
  }
}
.nav-enter-to, .nav-enter-active, .nav-leave-to {
  max-height: 0;
  transition: max-height 1s $transition;

  .half {
    max-height: 0vh;
  }
}
.nav-enter-to {
  max-height: 100vh;
  transition: max-height 1s $transition;

  .half {
    max-height: 50vh;
  }
}
.nav-enter-active, .nav-leave-active {
  .half {
    transition: all 1s $transition;
  }
} 

.navlist-enter-active, .navlist-leave-active {
  transition: all 1s $transition;
  opacity: 0 !important;
  transform: translateY(50%) !important;
}
.navlist-enter-to {
  opacity: 1;
  transform: translateY(0%);
  transition: all 1s $transition;
}
.navlist-leave-to {
  transition: all 1s $transition;
  opacity: 0 !important;
  transform: translateY(50%) !important;
}
.center {
  left: 50%;
  transform: translateX(-50%);
  transition: all 1.5s $transition;
  // width: 100%;
}

.component {
  position: fixed;
  left: 0;
  top: 50%;
  width: 100%;
  // height: 70vh;
  transform: translateY(-50%) translateX(0%);
  z-index: 4
}
.component-slide-in-enter-active, .component-slide-in-leave-active {
  transform: translateY(-50%) translateX(100%);
  transition: transform 1.5s $transition;
}

.component-slide-in-enter-to {
  transform: translateY(-50%) translateX(0%);
}
.component-slide-in-leave-to {
  transform: translateY(-50%) translateX(100%) !important;
}

.menu-slide-in-leave-active {
  transition: transform 1.5s $transition;
}
.menu-slide-in-leave-to {
  transition: all 1.5s $transition;
  transform: translateY(-50%) translateX(-100%) !important;
}

.go-back {
  position: fixed;
  top: 50%;
  left: 20px;
  z-index: 5;
  transform: rotate(180deg);
  mix-blend-mode: difference;
  top: 50%;
  width: 100px;

  cursor: pointer;
  @media screen and (max-width: $mobiledown) {
    top: 30px;
    width: 50px;
  }

  .arrow {
    pointer-events: none;
    width: 100%;
  }
}

.resume {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 11;
  mix-blend-mode: difference;

  small {
    color: white;
    cursor: pointer;
  }
}
</style>
