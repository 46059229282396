<template>
  <div id="index">
    <div id="index-inner">
      <transition appear name="page-swipe">
        <span id="router-view-wrapper" :key="$route">
          <router-view 
          :homepageData="homepageData"
          :scrolly="scrolly"
          :clickObj="clickObj"
          :resizeObj="resizeObj"></router-view>
        </span>
      </transition>
      <div id="dark-background"></div>
    </div>

    <NavMenu 
    @navOpen="navOpen = !navOpen"
    :clickObj="clickObj"
    :homepageData="homepageData"
    ></NavMenu>
    
  </div>
</template>

<script>

import NavMenu from './components/NavMenu.vue';
import Scrolly from "./scrolly.js";

export default {
  name: 'App',
  components: {
    NavMenu,
  },
  watch: {
    $route: {
      handler() {
          if ( this.$store.state.scrolly) {
            setTimeout(() => {
              this.$store.state.scrolly.scroll_To(0);
            }, 1500)
            setTimeout(() => {
              this.$nextTick(() => {
                this.$store.state.scrolly.resizeScreen();
                document.getElementById("dark-background").style.opacity = 1;
              })
            }, 2000)
        }
      },
    },
    clickObj(clickObj) {
      if (clickObj.target.id === "portfolio-cta") {
        this.$router.push(clickObj.target.getAttribute('url'))
        document.getElementById("dark-background").style = `
        opacity: 0;
        transition: all 1.5s ease;
        `
      }
    }
  },
  data() {
    return {
      app: document.getElementById("app"),
      scrolly: null,
      clickObj: null,
      resizeObj: null,
      homepageData: null
    }
  },
  computed() {
    
  },
  methods: {
    click(e) {
      this.clickObj = e;
    },
    resize(e) {
      if (this.$store.state.scrolly) {
        this.$store.state.scrolly.resizeScreen();
        this.$store.state.scrolly.scroll_To(Math.min(this.$store.state.scrolly.max, this.$store.state.scrollPos))
      }
      this.resizeObj = e;
    }
  },
  mounted() {
    // window.addEventListener("scroll", this.scroll);
    this.homepageData = this.$prismic.filter(i => i.type === "homepage")[0];
    setTimeout(() => {
      this.scrolly = new Scrolly(document.getElementById("index"));
      this.$store.commit("setScrolly", this.scrolly);
    }, 1000)
    document.addEventListener("click", this.click);
    window.addEventListener("resize", this.resize);
    
  }
}
</script>

<style lang="scss">
@import "./styles.scss";

.page-swipe-leave-to {
  transform: translateX(0%);
  opacity: 1;
  transition: all 1.5s ease;
}
.page-swipe-leave-active {
  transform: translateX(-100%);
  opacity: 0 !important;
  transition: all 1.5s ease;
}

#router-view-wrapper {
  display: block;
  position: relative;
}

#dark-background {
  background-image: url("~@/assets/paper-texture.jpg");
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: -1;
}
</style>
