import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    scrollPos: 0,
    scrolly: null
  },

  mutations: {
    setScrollPos(state, scrollPos) {
      state.scrollPos = scrollPos
    },
    setScrolly(state, scrolly) {
      state.scrolly = scrolly;
    }
  },

  actions: {

  },
  // plugins: [createPersistedState()],
});
