<template>
  <div id="WorkList">
    <span class="title">
      <transition appear name="fadeup">
        <span class="title-inner" v-show="show">
          WORK
        </span>
      </transition>
    </span>
      <div v-for="(item, i) in portfolio" :key="i" class="worklist-outer">
        <span>
          <transition appear name="fadeup">
            <a v-show="show" :work-url="`/work/` + $cms.textField(item.data.slug)">
              <strong :work-url="`/work/` + $cms.textField(item.data.slug)">
                  <h2 :work-url="`/work/` + $cms.textField(item.data.slug)">{{ $cms.textField(item.data.title) }}</h2>
              </strong>
            </a>
          </transition>
       </span>
    </div>
  </div>
</template>

<script>
// import { toRaw } from 'vue';

export default {
  name: 'WorkPage',
  components: {
  },
  props: {
    clickObj: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    clickObj: {
        handler(clickObj) {
          if (clickObj.target.getAttribute('work-url')) {
            this.show = false;
            this.$router.push(clickObj.target.getAttribute('work-url'))
            setTimeout(() => {
              this.$emit("closeNav")
            }, 1500)
          }
        }
    }
  },
  data() {
    return {
        portfolio: null,
        show: true
    }
  },
  emits: ['closeNav'],
  computed: {
  },
  methods: {
  },
  beforeMount() {
  },
  mounted() {
    this.portfolio = this.$prismic.filter(i => i.type === "work");
  },
  updated() {
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles.scss";

#WorkList {
    overflow: scroll;
    height: 475px;
    .title {
        margin-bottom: 40px;
        display: block;
        .title-inner {
          display: block;
        }
    }

    .worklist-outer {
      overflow: hidden;
    }


    a {
      cursor: pointer;
      strong {
        &:after {
            font-size: $size-header;
            @media screen and (max-width: $mobiledown) {
              font-size: $size-subheader
            }
        }
      }
      h2 {
          color: black;
          text-transform: uppercase;
          font-size: $size-header;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;

          @media screen and (max-width: $mobiledown) {
            font-size: $size-subheader
          }

      }
  }
}
.fadeup-leave-active {
  transition: transform 1s $transition;
}
.fadeup-leave-to {
  transform: translateY(-100%);
}
</style>
