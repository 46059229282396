
import { createRouter, createWebHistory } from 'vue-router'

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    { 
      path: '/', 
      component: () => import("./App.vue"),
    },
    { 
      path: '/work', 
      component: () => import("./components/WorkPage.vue"),
    },
    { 
      path: '/work/:slug', 
      component: () => import("./components/WorkPage.vue"),
    },
    { 
      path: '/:pathMatch(.*)*',
      component: () => import("./components/LandingPage404.vue"),
    }
  ]
})

export default router;
