
import PrismicJS from "prismic-javascript";
const endpoint = "https://designbyrebj.cdn.prismic.io/api/v2";

let prismic = await PrismicJS.getApi(endpoint, {accessToken: process.env.VUE_APP_PRISMIC_TOKEN}).then((api) => {
    return api.query('');
}).then((res) => {
    return res.results;
})

export default prismic;

