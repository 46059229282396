<template>
    <!-- <router-link :to="url"> -->
      <div id="arrow" 
      :class="`ArrowCTA ${hovertext ? `hovertext` : ``}`"
      :url="url">
        <span v-show="hovertext" class="hovertext-span">
          {{ hovertext }}
        </span>
          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.82 31.09">
            <g id="Layer_1-2" data-name="Layer 1">
              <path 
              class="cls-1" 
              :style="`fill: none; stroke: ${color};`"
              d="m109.94,30.92s5.32-14.65,19.86-15.38H0h129.8C115.25,14.82,109.94.17,109.94.17"/>
            </g>
          </svg>
      </div>
  <!-- </router-link> -->

</template>

<script>
// import Scrolly from "../scrolly.js";
/* eslint-disable no-unused-vars */
export default {
  name: 'ArrowCTA',
  components: {
  },
  props: {
    direction: {
      type: String,
      default: "right"
    },
    color: {
      type: String,
      default: "grey"
    },
    url: {
      type: String,
      default: null
    },
    hovertext: {
      type: String,
      default: null
    }
  },
  watch: {
  },
  data() {
    return {

    }
  },
  emitted: [''],
  methods: {

  },
  mounted() {

    }   
}
</script>

<style lang="scss">
@import "~@/styles.scss";

.ArrowCTA {
  position: relative;
}

.hovertext {
  overflow: hidden;
  cursor: pointer;

  #Layer_2 {
    transform: translateX(0%);
    transition: all .5s ease .25s !important;
    pointer-events: none;
  }
  .hovertext-span {
    mix-blend-mode: difference;
    color: white;
    font-family: Helvetica Neue;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    position: absolute;
    top: 50%;
    transition: all .5s $transition;
    transform: translateY(-70%) translateX(-100%);
    pointer-events: none;
  }
  &:hover {
    #Layer_2 {
      transform: translateX(100%);
      transition: all .5s ease !important;
    }
    .hovertext-span {
      transition: all .5s ease !important;
      transform: translateY(-70%) translateX(0%);
    }
  }

}

</style>
